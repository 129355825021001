.navbarWrapper {
  background-color: #fff;
}

.navbarContainer {
  padding: 0rem !important;
  margin: 0 !important;
  max-width: 100% !important;
  width: 100vw !important;
  display: flex !important;
  align-items: center !important;
  height: 2rem !important;
}

.nav-link {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
}

.languageActive.nav-link.active {
  color: #000 !important;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: green;
}

.navbar-brand {
  padding: 0.25rem 0.75rem;
  margin-left: 3rem;
}

.translate {
  margin-right: 3rem;
}

.navbar-toggler-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbarLogo {
  width: 2rem;
  height: 1.6rem;
}

.navbar-nav .nav-link {
  margin: 0;
}

.navbar-toggler.collapsed > .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler > .navbar-toggler-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../asset/images/navbar_menu_close.svg");
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 0;
  }

  .nav-link {
    padding: 1rem !important;
    margin: 0;
    font-size: 0.75rem;
  }

  .navbar-brand {
    margin-left: 0rem;
  }

  .translate {
    margin-right: 0;
    padding: 0 !important;
  }
}
