body {
  font-size: 32px;
}

.main {
  padding-top: 72px;
}

/**** Worked for Image present on outside server *****/
.slick-prev:before {
  width: 50px;
  height: 50px;
  content: "" !important;
  position: absolute;
  top: -11px;
  left: -31px;
  background-image: url(./asset/images/prev-arrow.svg);
  background-position: center;
}

.slick-next:before {
  width: 50px;
  height: 50px;
  content: "" !important;
  position: absolute;
  top: -11px;
  left: 0px;
  background-image: url(./asset/images/next-arrow.svg);
  background-position: center;
}

@media screen and (max-width: 767px) {
  .slick-slider {
    margin-top: 1.5rem;
  }
}
