@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: 300;
  src: url(./asset/fonts/NotoSansKR-Light.woff2) format("woff2"),
    url(./asset/fonts/NotoSansKR-Light.woff) format("woff"),
    url(./asset/fonts/NotoSansKR-Light.otf) format("opentype");
}
@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: normal;
  src: url(./asset/fonts/NotoSansKR-Regular.woff2) format("woff2"),
    url(./asset/fonts/NotoSansKR-Regular.woff) format("woff"),
    url(./asset/fonts/NotoSansKR-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: 500;
  src: url(./asset/fonts/NotoSansKR-Medium.woff2) format("woff2"),
    url(./asset/fonts/NotoSansKR-Medium.woff) format("woff"),
    url(./asset/fonts/NotoSansKR-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: bold;
  src: url(./asset/fonts/NotoSansKR-Bold.woff2) format("woff2"),
    url(./asset/fonts/NotoSansKR-Bold.woff) format("woff"),
    url(./asset/fonts/NotoSansKR-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: 900;
  src: url(./asset/fonts/NotoSansKR-Black.woff2) format("woff2"),
    url(./asset/fonts/NotoSansKR-Black.woff) format("woff"),
    url(./asset/fonts/NotoSansKR-Black.otf) format("opentype");
}

@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: normal;
  src: url(./asset/fonts/NanumGothic-Regular.woff2) format("woff2"),
    url(./asset/fonts/NanumGothic-Regular.woff) format("woff"),
    url(./asset/fonts/NanumGothic-Regular.otf) format("opentype");
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: bold;
  src: url(./asset/fonts/NanumGothic-Bold.woff2) format("woff2"),
    url(./asset/fonts/NanumGothic-Bold.woff) format("woff"),
    url(./asset/fonts/NanumGothic-Bold.otf) format("opentype");
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Fonts";
  font-style: normal;
  font-weight: 900;
  src: url(./asset/fonts/NanumGothic-ExtraBold.woff2) format("woff2"),
    url(./asset/fonts/NanumGothic-ExtraBold.woff) format("woff"),
    url(./asset/fonts/NanumGothic-ExtraBold.otf) format("opentype");
  unicode-range: U+0041-005A, U+0061-007A;
}

* {
  font-family: "Fonts";
}

body {
  font-size: 32px;
  margin: 0;
  font-family: "Fonts";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Fonts";
}

h1,
h2,
h3,
p {
  word-break: keep-all;
}

@media screen and (min-width: 992px) {
  html {
    font-size: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  html {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 16px;
  }
}
