.newMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.625rem;
  box-sizing: border-box;
  background: url(../../asset/images/news_main_image.jpg);
  background-position: 0% 0%;
  height: 100vh;
  padding-top: 96px;
  background-size: cover;
  color: #ffffff;
  filter: brightness(50%);
}

.mainTitle {
  margin-bottom: 24px;
  font-size: 2rem;
  font-weight: bold;
  z-index: 999;
}

.mainSubTitle {
  font-size: 1rem;
}
