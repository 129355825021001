.pageErrorWebView {
  margin-top: 5em;
  text-align: center;
}

.pageError {
  margin-top: 1.5rem;
  text-align: center;
}

.errorTitleColor {
  margin: 8px 0;
  font-size: 1.1rem;
  color: #005500;
}

.errorTitle {
  margin: 8px 0;
  font-size: 60px;
  color: #005500;
}

.errorTitleWebview {
  margin: 8px 0;
  font-size: 1.2rem;
}

.WebViewBtn {
  color: #005500;
  background: #fff;
  border: 1px solid #005500;
  box-shadow: 0 1px 1px 0 rgb(209 213 217 / 30%);
  display: inline-block;
  width: 140px;
  padding: 14px 0;
  margin: 1.5rem 2px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  border-radius: 4px;
}

.errorDescription {
  margin: 20px 0;
  font-size: 18px;
  line-height: 1.6em;
  color: #525a61;
}

.prevBtn {
  color: #005500;
  background: #fff;
  border: 1px solid #005500;
  box-shadow: 0 1px 1px 0 rgb(209 213 217 / 30%);
}

.homeBtn {
  color: #ffffff;
  background: #005500;
  border: 1px solid #798086;
  box-shadow: 0 1px 1px 0 rgb(209 213 217 / 30%);
}

.errorBtn {
  display: inline-block;
  width: 140px;
  padding: 14px 0;
  margin: 0 2px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  border-radius: 4px;
}
